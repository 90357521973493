import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Grid, Skeleton } from '@mui/material';
import { editUser, getSingleUser, IUser } from '../../services/userService';
import { EditedList } from '../../components/EditedList';
import { roleSelector, userIdSelector } from '../../redux/selectors/authSelector';
import { IEditedListItem, Roles } from '../../global/types/types';
import EditCard from '../../components/EditCard';
import { updatedSingleCustomer } from '../../utils';
import BackButton from '../../components/BackButton';

const SingleUserPage = () => {
  const { id } = useParams();
  const role = useSelector(roleSelector);
  const authorizedUserId = useSelector(userIdSelector);

  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [requestError, setRequestError] = useState('');
  const [fieldList, setFieldList] = useState<IEditedListItem[]>([]);
  const [errors, setErrors] = useState<Record<string, string>[]>([]);

  const convertToValidData = useCallback((userData: IUser) => {
    return [
      {
        title: 'First Name',
        info: userData?.firstName,
        fieldName: 'firstName',
        isEditable: true,
      },
      {
        title: 'Last Name',
        info: userData?.lastName,
        fieldName: 'lastName',
        isEditable: true,
      },
      {
        title: 'Customer Name',
        info: userData?.company.name,
        isEditable: false,
      },
      {
        title: 'User Type',
        info: userData?.role.name,
        isEditable: false,
      },
      {
        title: 'User Status',
        info: userData?.status,
        isEditable: false,
      },
      {
        title: 'Email address',
        info: userData?.email,
        isEditable: false,
      },
      {
        title: 'Last login date and time',
        info: userData?.loginAt && new Date(userData?.loginAt).toLocaleString(),
        isEditable: false,
      },
    ];
  }, []);

  useEffect(() => {
    const fetchSingleUser = async () => {
      try {
        setIsLoading(true);
        const response = await getSingleUser(id as string);

        if (response && response.status === 200) {
          setUser(response.data.data);
          setFieldList(convertToValidData(response.data.data));
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSingleUser();
  }, [convertToValidData, id]);

  const toggleEditModeHandle = useCallback(() => {
    setIsEdit((prevState) => !prevState);
  }, []);

  const submitHandle = useCallback(async () => {
    try {
      setIsLoading(true);

      const updatedData = updatedSingleCustomer(fieldList);

      const response = await editUser(id as string, {
        firstName: updatedData.firstName ? updatedData.firstName.toString() : null,
        lastName: updatedData.lastName ? updatedData.lastName.toString() : null,
      });

      if (response && response.status === 200) {
        setUser(response.data.data);
        setRequestError('');
      }
    } catch (e: any) {
      setRequestError(e.error.message);
    } finally {
      setIsLoading(false);
      toggleEditModeHandle();
    }
  }, [id, fieldList, toggleEditModeHandle]);

  const isIdMatched = useMemo(() => {
    return id === authorizedUserId;
  }, [id, authorizedUserId]);

  return (
    <Grid container>
      {isLoading ? (
        <Skeleton variant='rectangular' width={490} height={568} sx={{ borderRadius: '4px' }} />
      ) : (
        <>
          <BackButton to='/users' style={{ marginBottom: '20px' }} />

          {user && (
            <Grid container>
              {requestError && (
                <Grid container justifyContent='flex-start' alignItems='center' marginTop={2}>
                  <Alert severity='error'>{requestError}. User was not edited</Alert>
                </Grid>
              )}
              <EditCard
                isEdit={isEdit}
                isEditable={role.name !== Roles.USER || isIdMatched}
                errors={errors}
                toggleEditModeHandle={toggleEditModeHandle}
                onSubmitHandle={submitHandle}
              >
                <EditedList fieldList={fieldList} isEdit={isEdit} setErrors={setErrors} setFieldList={setFieldList} />
              </EditCard>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default SingleUserPage;
